<template>
  <span :class="$style.loader">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </span>
</template>

<style lang="scss" module>
.loader {
  position: relative;
  display: block;
  margin-inline: auto;
  width: 80px;
  aspect-ratio: 1/1;

  > span {
    position: absolute;
    top: 50%;
    left: 50%;
    border: 2px solid var(--accent-color);
    border-bottom: 5px solid transparent;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    animation: load 4s cubic-bezier(0.5, 0.2, 0.5, 0.83) infinite;
    aspect-ratio: 1;

    &:nth-child(1) {
      width: 100%;
      animation-direction: forwards;
    }
    &:nth-child(2) {
      width: 80%;
      animation-direction: reverse;
    }
    &:nth-child(3) {
      width: 60%;
      animation-direction: forwards;
    }
    &:nth-child(4) {
      width: 40%;
      animation-direction: reverse;
    }
    &:nth-child(5) {
      width: 20%;
      animation-direction: forwards;
    }
  }
}

@keyframes load {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(720deg);
  }
}
</style>
